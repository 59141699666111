import React from "react";
import Layout from "../components/layout";
import SectionCard from "../components/section-card";
import SEO from "../components/seo";

import { css } from "@emotion/core";

const AboutPage: React.FC = () => (
  <Layout transparentHeaderOnTop={false}>
    <SEO title="Acerca de nosotros" />
    <div className="container">
      <div
        css={{
          borderRadius: "0 0 3em 3em",
          overflow: "hidden",
          minHeight: "60vh",
          width: "80%",
          margin: "0 auto",
        }}
        className="columns bg-white"
      >
        <div
          css={{
            backgroundImage: "url(/images/about-us-header.png)",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            paddingTop: "80px",
          }}
          className="column text-white center-items-flex"
        >
          <div className="pb-6 px-4">
            <h2 className="display-4">
              <span className="text-thin">SOBRE</span> CUBIT
            </h2>
            <p>Ayudamos a las empresas a dar el paso digital a través de consultoría de acción.</p>
          </div>
        </div>

        <div className="column center-items-flex">
          <div className="px-4 py-6">
            <div className="w-100 text-obscure">
              <h2>Sobre Nosotros</h2>
              <p css={{ opacity: "0.4" }}>Somos un equipo donde el conocimiento individual aporta al colectivo, desarrollando proyectos de alto nivel en diferentes sectores de la economía</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className="container px-5" css={{ marginTop: "2em", marginBottom:"-7em" }}>
      <div className="columns text-obscure">
        <div className="column">
        </div>
        <div className="column">
          <i >
            <div css={{ fontSize: "16px", textAlign: "end" }}>Trabajamos con marcas inspiradoras y apoyamos a surgir sueños emergentes</div>
          </i>
        </div>
        <div className="column">
          <div css={{ fontSize: "14px", opacity: "0.4" }}>
            Cubit es un conjunto de personas con hambre de aprender y aplicar
            de manera innovadora todo su conocimiento, nos gusta lo tangible,
            los desarrollos ágiles, la evolución constante y nos orientan los
            resultados. El mercado nos ha permitido trabajar con marcas
            inspiradoras y darle arranque a proyectos nuevos.</div>
        </div>
        <div className="column">
        </div>
      </div>
    </section>

    <div className="container" css={{ marginBottom: "-30px"}}>
      <SectionCard>
        <img src="/images/devices-image.png" className="img-fluid" />

      </SectionCard>
    </div>
  </Layout>
);

export default AboutPage;
